function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) {
    try {
        var info = gen[key](arg);
        var value = info.value;
    } catch (error) {
        reject(error);
        return;
    }
    if (info.done) {
        resolve(value);
    } else {
        Promise.resolve(value).then(_next, _throw);
    }
}
function _async_to_generator(fn) {
    return function() {
        var self = this, args = arguments;
        return new Promise(function(resolve, reject) {
            var gen = fn.apply(self, args);
            function _next(value) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value);
            }
            function _throw(err) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err);
            }
            _next(undefined);
        });
    };
}
function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _object_spread_props(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
import { OurProductFooterLinks, OurProductFooterLinksV2, OurTeamFooterLinks, OurTeamFooterLinksV2, UseCasesFooterLinks, UseCasesFooterLinksV2, HelpFooterLinks, ResourcesFooterLinks, NewSecurityFooterLinks, Header, LocalizedHeader, ShortHeader, ProductsFooterLinks, FeaturesFooterLinks, MondayFooterLinks, MoreByMondayFooterLinks, slimFooterLinks } from "/constants/links";
import { LocalizedResourcesLinks, LocalizedOurProductFooterLinksV2, LocalizedProductsFooterLinks, LocalizedUseCasesFooterLinks } from "/constants/localized-links";
import { SUPPORTED_LOCALE_IDS } from "/constants/localization";
import englishTranslations from "services/localization/keys/all.json";
import { TRANSLATIONS_INTERNAL_ERROR } from "/constants/bigbrain-event-types";
import { trackBigBrainEventOnServer } from "/server/services/bigbrain-tracking-service/bigbrain-tracking-service";
import { getCachedData } from "/server/services/data-service/providers/base-data-provider";
import TranslationsDataProvider from "/server/services/data-service/providers/translations-data-provider";
import { HOMEPAGE_PROJECT } from "/constants/translations";
export const getFooterComponents = (localeId, abTests, forceGetAllComponents = false, additionalProps = {})=>{
    const { pageClusterConfig, useProductMiniSiteConfig } = additionalProps;
    const SlimFooterLinks = slimFooterLinks(pageClusterConfig, {
        useProductMiniSiteConfig
    });
    if (isSupportedLocale(localeId) && !forceGetAllComponents) return {
        MondayFooterLinks,
        FeaturesFooterLinks,
        ProductsFooterLinks: updateLocalizedStaticLinks(localeId, ProductsFooterLinks, LocalizedProductsFooterLinks),
        MoreByMondayFooterLinks,
        OurProductFooterLinks,
        OurProductFooterLinksV2: updateLocalizedStaticLinks(localeId, OurProductFooterLinksV2, LocalizedOurProductFooterLinksV2),
        OurTeamFooterLinks,
        OurTeamFooterLinksV2,
        ResourcesFooterLinks: updateLocalizedStaticLinks(localeId, ResourcesFooterLinks, LocalizedResourcesLinks),
        HelpFooterLinks,
        NewSecurityFooterLinks,
        SlimFooterLinks
    };
    return {
        MondayFooterLinks,
        FeaturesFooterLinks,
        ProductsFooterLinks: updateLocalizedStaticLinks(localeId, ProductsFooterLinks, LocalizedProductsFooterLinks),
        MoreByMondayFooterLinks,
        OurProductFooterLinks,
        OurProductFooterLinksV2: updateLocalizedStaticLinks(localeId, OurProductFooterLinksV2, LocalizedOurProductFooterLinksV2),
        OurTeamFooterLinks,
        OurTeamFooterLinksV2,
        UseCasesFooterLinks,
        UseCasesFooterLinksV2: updateLocalizedStaticLinks(localeId, UseCasesFooterLinksV2, LocalizedUseCasesFooterLinks),
        ResourcesFooterLinks: updateLocalizedStaticLinks(localeId, ResourcesFooterLinks, LocalizedResourcesLinks),
        HelpFooterLinks,
        NewSecurityFooterLinks,
        SlimFooterLinks
    };
};
export const getHeaderComponents = (localeId, showShortHeader = false)=>{
    if (isSupportedLocale(localeId)) return LocalizedHeader;
    return showShortHeader ? ShortHeader : Header;
};
export const isSupportedLocale = (localeId)=>{
    const filteredArray = SUPPORTED_LOCALE_IDS.filter((supportedLocale)=>supportedLocale === localeId);
    return filteredArray.length > 0;
};
const updateLocalizedStaticLinks = (localeId, resourceLinks, allLocalesLocalizedLinks)=>{
    const localeLocalizedLinks = allLocalesLocalizedLinks[localeId];
    const localizedLinks = _object_spread({}, resourceLinks.links, localeLocalizedLinks);
    // Filter the localizedLinks to only include keys that start with "footer"
    const filteredLinks = Object.fromEntries(Object.entries(localizedLinks).filter(([key, value])=>key.startsWith("footer")));
    return _object_spread_props(_object_spread({}, resourceLinks), {
        links: filteredLinks
    });
};
export const getTranslationsFromApi = function() {
    var _ref = _async_to_generator(function*(localeId, useNewTranslationsApi) {
        if (!isSupportedLocale(localeId)) return englishTranslations;
        let translations = {};
        try {
            const provider = new TranslationsDataProvider({
                localeId,
                project: HOMEPAGE_PROJECT,
                useNewTranslationsApi
            });
            translations = yield getCachedData(provider);
        } catch (exception) {
            trackBigBrainEventOnServer(TRANSLATIONS_INTERNAL_ERROR, {
                info1: exception.message,
                info2: localeId
            });
        }
        return _object_spread({}, englishTranslations, translations);
    });
    return function getTranslationsFromApi(localeId, useNewTranslationsApi) {
        return _ref.apply(this, arguments);
    };
}();

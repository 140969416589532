function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) {
    try {
        var info = gen[key](arg);
        var value = info.value;
    } catch (error) {
        reject(error);
        return;
    }
    if (info.done) {
        resolve(value);
    } else {
        Promise.resolve(value).then(_next, _throw);
    }
}
function _async_to_generator(fn) {
    return function() {
        var self = this, args = arguments;
        return new Promise(function(resolve, reject) {
            var gen = fn.apply(self, args);
            function _next(value) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value);
            }
            function _throw(err) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err);
            }
            _next(undefined);
        });
    };
}
const { trackBigBrainEventOnServer } = require("../../bigbrain-tracking-service/bigbrain-tracking-service");
const { BaseDataProvider } = require("./base-data-provider");
const ProviderKeys = require("./providers-keys");
const REFRESH_INTERVAL_MINUTES = 15 * 60;
const URL_BASE = process.env.MONDAY_BASE_PATH;
const TRANSLATIONS_URL_BASE = `${URL_BASE}/localization/get`;
class TranslationsDataProvider extends BaseDataProvider {
    key() {
        const { localeId, project } = this.params();
        return `${ProviderKeys.TRANSLATIONS}_locale_${localeId}_project_${project}`;
    }
    refreshInterval() {
        return REFRESH_INTERVAL_MINUTES;
    }
    getData() {
        var _this = this;
        return _async_to_generator(function*() {
            const { localeId, project, useNewTranslationsApi } = _this.params();
            try {
                const options = {
                    headers: {
                        "Content-Type": "application/json"
                    },
                    method: "GET"
                };
                const url = `${TRANSLATIONS_URL_BASE}/${localeId}/${project}/${useNewTranslationsApi}`;
                const res = yield fetch(url, options);
                if (res && res.status === 200) {
                    const response = yield res.json();
                    return response;
                }
                throw new Error(res.statusText);
            } catch (e) {
                trackBigBrainEventOnServer("translations_internal_fetch_error", {
                    direct_object: e.message
                });
                console.error(e.message);
                throw e;
            }
        })();
    }
}
module.exports = TranslationsDataProvider;
